import * as React from 'react';
import { MultiTemplateModel } from '../../common/models/multi-template-model';
import { PicColor, PicFontSize, PicFontWeight, PicRouter, PicText } from '@cardinalblue/pic-collage-cms-ui';

interface Props {
  template: MultiTemplateModel;
}

const MultiTemplateContentSection: React.FC<Props> = ({ template }) => {
  const picRouter = React.useMemo(() => new PicRouter(), []);
  const pagesInfo = template.getPagesInfo();

  const goToSingleTemplatePage = React.useCallback(
    (templateId: number) => () => {
      picRouter.open(`/templates/${templateId}`);
    },
    [picRouter],
  );

  return (
    <div style={{ display: 'flex', flexFlow: 'column', gap: '36px' }}>
      <PicText size={PicFontSize.Xl} weight={PicFontWeight.Medium}>
        Pages
      </PicText>
      <div style={{ display: 'flex', flexFlow: 'row', flexWrap: 'wrap' }}>
        {pagesInfo &&
          pagesInfo.map((pageInfo, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexFlow: 'column',
                gap: '4px',
                maxWidth: '150px',
                maxHeight: '250px',
                margin: '10px 5px 5px 0',
                justifyContent: 'flex-end',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: PicColor.White,
                borderImage: 'initial',
                cursor: 'pointer',
              }}
              onClick={goToSingleTemplatePage(pageInfo.getTemplateId())}
            >
              <img
                src={pageInfo.getTemplateThumbnailUrl()}
                alt={'Page ' + pageInfo.getTemplateId().toString()}
                style={{ width: '100%' }}
              />
              <PicText>Page: {index + 1}</PicText>
              <PicText>ID: {pageInfo.getTemplateId()}</PicText>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultiTemplateContentSection;
