import * as React from 'react';
import { CommonContextProvider } from '../features/common/contexts/common-context';
import {
  PicButton,
  PicRowField,
  PicText,
  PicNotifier,
  PicLink,
  PicRouter,
  PicContainer,
  PicFontSize,
  PicFontWeight,
  PicColor,
  PicCheckbox,
  PicDropdown,
  PicTagsInput,
  PicImageUpload,
  PicImage,
  PicNumberInput,
  PicIconButton,
  PicIconName,
  PicConfirmer,
} from '@cardinalblue/pic-collage-cms-ui';
import { TemplateApi } from '../features/templates/apis/template-api';
import { SingleTemplateModel } from '../features/common/models/single-template-model';
import { MultiTemplateModel } from '../features/common/models/multi-template-model';
import { parseTemplateTagDto, TemplateTagDto } from '../features/common/dtos/template-tag-dto';
import SingleTemplateContentSection from '../features/templates/components/single-template-contents';
import MultiTemplateContentSection from '../features/templates/components/multi-template-contents';
import { getTemplateTagNameById } from '../features/common/utils/template-tag-helpers';

type Props = {
  formAuthenticityToken: string;
  templateId: number;
  templateTagDtos: TemplateTagDto[];
  creatorIds: string[];
  allTemplateLabelNames: string[];
};

const EditTemplatePageContent: React.FC<Props> = ({
  formAuthenticityToken,
  templateId,
  templateTagDtos,
  creatorIds,
  allTemplateLabelNames,
}) => {
  const templateApi = React.useMemo(() => TemplateApi.create(), []);
  const templateTags = React.useMemo(() => templateTagDtos.map((dto) => parseTemplateTagDto(dto)), [templateTagDtos]);
  const picConfirmer = React.useMemo(() => new PicConfirmer(), []);
  const picNotifier = React.useMemo(() => new PicNotifier(), []);
  const picRouter = React.useMemo(() => new PicRouter(), []);
  const thumbnailInputRef = React.useRef<HTMLInputElement>(null);
  const animatedThumbnailInputRef = React.useRef<HTMLInputElement>(null);
  const [template, setTemplate] = React.useState<SingleTemplateModel | MultiTemplateModel | null>(null);
  const [draftTemplate, setDraftTemplate] = React.useState<SingleTemplateModel | MultiTemplateModel | null>(null);
  const [suitablePhotosCountValidation, setSuitablePhotosCountValidation] = React.useState<{
    isValid: boolean;
    message?: string;
  }>({
    isValid: true,
  });

  const fetchTemplate = React.useCallback(async (): Promise<Error | null> => {
    const [error, returnedTemplate] = await templateApi.fetchTemplatebyId(templateId);
    if (error) {
      picNotifier.notify({
        type: 'error',
        message: error.message,
      });
      return error;
    }
    setTemplate(returnedTemplate);
    return null;
  }, [templateApi, picNotifier]);

  React.useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  const resetDraftTemplate = React.useCallback(() => {
    setDraftTemplate(template ? template.clone() : null);
  }, [template]);

  React.useEffect(() => {
    resetDraftTemplate();
  }, [resetDraftTemplate]);

  const templateCreatorIdsGroups = React.useMemo(() => {
    if (!creatorIds) return [];
    const uniqueCreatorIds = Array.from(new Set(creatorIds));
    return [{ title: 'Creator Ids', items: uniqueCreatorIds }];
  }, [creatorIds]);

  const templateLabelNames = React.useMemo(() => {
    return allTemplateLabelNames;
  }, [allTemplateLabelNames]);

  const minimumSuitablePhotosCount = React.useMemo(() => {
    return 0;
  }, []);

  const maximumSuitablePhotosCount = React.useMemo(() => {
    return 100;
  }, []);

  const acceptableMimeTypes = React.useMemo(() => {
    return ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'];
  }, []);

  const goToSourceUrl = React.useCallback(() => {
    if (template?.getSourceUrl()) {
      picRouter.open(template.getSourceUrl());
    }
  }, [template]);

  const goToShowTemplatePage = React.useCallback(
    (templateId: number) => {
      picRouter.redirect(`/templates/${templateId}/new_show_page`);
    },
    [picRouter],
  );

  const handleTemplateIsAdjustableChange = React.useCallback(() => {
    if (!draftTemplate) return;
    setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
      if (!prevTemplate) return null;
      const updatedTemplate = prevTemplate.clone();
      updatedTemplate.updateIsAdjustable(!draftTemplate.getIsAdjustable());
      return updatedTemplate;
    });
  }, [draftTemplate]);

  const handleTemplateCreatorIdChange = React.useCallback((newCreatorId: string) => {
    setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
      if (!prevTemplate) return null;
      const updatedTemplate = (prevTemplate as SingleTemplateModel).clone();
      updatedTemplate.updateCreatorId(newCreatorId);
      return updatedTemplate;
    });
  }, []);

  const checkTemplateSuitablePhotosCount = React.useCallback(
    (lowerSuitablePhotosCount: number | null, upperSuitablePhotosCount: number | null) => {
      if (!draftTemplate) return;
      if (lowerSuitablePhotosCount === null) {
        setSuitablePhotosCountValidation({
          isValid: false,
          message: 'Lower suitable photos count should not be empty',
        });
      } else if (upperSuitablePhotosCount && lowerSuitablePhotosCount > upperSuitablePhotosCount) {
        setSuitablePhotosCountValidation({
          isValid: false,
          message: 'Lower suitable photos count should not be greater than upper suitable photos count',
        });
      } else if (
        lowerSuitablePhotosCount > maximumSuitablePhotosCount ||
        lowerSuitablePhotosCount < minimumSuitablePhotosCount
      ) {
        setSuitablePhotosCountValidation({
          isValid: false,
          message: `Lower suitable photos count should be between ${minimumSuitablePhotosCount} and ${maximumSuitablePhotosCount}`,
        });
      } else if (
        (upperSuitablePhotosCount && upperSuitablePhotosCount > maximumSuitablePhotosCount) ||
        (upperSuitablePhotosCount && upperSuitablePhotosCount < minimumSuitablePhotosCount)
      ) {
        setSuitablePhotosCountValidation({
          isValid: false,
          message: `Upper suitable photos count should be between ${minimumSuitablePhotosCount} and ${maximumSuitablePhotosCount}`,
        });
      } else {
        setSuitablePhotosCountValidation({
          isValid: true,
        });
      }
    },
    [draftTemplate],
  );

  const handleTemplateLowerSuitablePhotosCountChange = React.useCallback(
    (newLowerSuitablePhotosCount: number | null) => {
      if (!draftTemplate) return;
      checkTemplateSuitablePhotosCount(newLowerSuitablePhotosCount, draftTemplate.getUpperSuitablePhotosCount());
      setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
        if (!prevTemplate) return null;
        const updatedTemplate = prevTemplate.clone();
        updatedTemplate.updateLowerSuitablePhotosCount(newLowerSuitablePhotosCount);
        return updatedTemplate;
      });
    },
    [draftTemplate, checkTemplateSuitablePhotosCount],
  );

  const handleTemplateUpperSuitablePhotosCountChange = React.useCallback(
    (newUpperSuitablePhotosCount: number | null) => {
      if (!draftTemplate) return;
      checkTemplateSuitablePhotosCount(draftTemplate.getLowerSuitablePhotosCount(), newUpperSuitablePhotosCount);
      setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
        if (!prevTemplate) return null;
        const updatedTemplate = prevTemplate.clone();
        updatedTemplate.updateUpperSuitablePhotosCount(newUpperSuitablePhotosCount);
        return updatedTemplate;
      });
    },
    [draftTemplate, checkTemplateSuitablePhotosCount],
  );

  const handleTemplateCategoriesChange = React.useCallback(
    (templateTagNames: string[]) => {
      setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
        if (!prevTemplate) return null;
        const updatedTemplate = prevTemplate.clone();
        const updatedTemplateTagNames = templateTagNames.filter((templateTagName) =>
          templateTags.some((tag) => tag.getName() === templateTagName),
        );
        const newTemplateTagNames = templateTagNames.filter(
          (templateTagName) => !templateTags.some((tag) => tag.getName() === templateTagName),
        );
        updatedTemplate.updateTemplateTagIds(
          updatedTemplateTagNames.map(
            (category) => templateTags.find((tag) => tag.getName() === category)?.getId() ?? 0,
          ),
        );
        updatedTemplate.updateNewTemplateTagNames(newTemplateTagNames);
        return updatedTemplate;
      });
    },
    [templateTags],
  );

  const handleTemplateLabelsChange = React.useCallback((labels: string[]) => {
    setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
      if (!prevTemplate) return null;
      const updatedTemplate = prevTemplate.clone();
      updatedTemplate.updateLabels(labels);
      return updatedTemplate;
    });
  }, []);

  const handleTemplateShouldRemoveAnimatedThumbnailChange = React.useCallback(() => {
    if (!draftTemplate) return;
    setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
      if (!prevTemplate) return null;
      const updatedTemplate = prevTemplate.clone();
      updatedTemplate.updateShouldRemoveAnimatedThumbnail(!draftTemplate.getShouldRemoveAnimatedThumbnail());
      return updatedTemplate;
    });
  }, [draftTemplate]);

  const convertImageFileToBinary = React.useCallback(async (imageFile: File) => {
    if (!imageFile) return null;
    try {
      const arrayBuffer = await imageFile.arrayBuffer();
      const binaryData = new Uint8Array(arrayBuffer);
      return binaryData;
    } catch (error) {
      picNotifier.notify({
        type: 'error',
        message: 'Failed to upload image',
      });
      return null;
    }
  }, []);

  const handleTemplateThumbnailBinaryChange = React.useCallback(
    async (imageFile: File | null) => {
      if (!imageFile) {
        if (thumbnailInputRef.current) {
          thumbnailInputRef.current.value = '';
        }
        setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
          if (!prevTemplate) return null;
          const updatedTemplate = prevTemplate.clone();
          updatedTemplate.updateThumbnailBinary(null);
          return updatedTemplate;
        });
        return;
      }
      if (!draftTemplate) return;
      const binary = await convertImageFileToBinary(imageFile);
      if (!binary) return;
      setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
        if (!prevTemplate) return null;
        const updatedTemplate = prevTemplate.clone();
        updatedTemplate.updateThumbnailBinary(binary);
        return updatedTemplate;
      });
    },
    [draftTemplate, convertImageFileToBinary],
  );

  const handleTemplateAnimatedThumbnailBinaryChange = React.useCallback(
    async (imageFile: File | null) => {
      if (!imageFile) {
        if (animatedThumbnailInputRef.current) {
          animatedThumbnailInputRef.current.value = '';
        }
        setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
          if (!prevTemplate) return null;
          const updatedTemplate = prevTemplate.clone();
          updatedTemplate.updateAnimatedThumbnailBinary(null);
          return updatedTemplate;
        });
        return;
      }
      if (!draftTemplate) return;
      const binary = await convertImageFileToBinary(imageFile);
      if (!binary) return;
      setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
        if (!prevTemplate) return null;
        const updatedTemplate = prevTemplate.clone();
        updatedTemplate.updateAnimatedThumbnailBinary(binary);
        return updatedTemplate;
      });
    },
    [draftTemplate, convertImageFileToBinary],
  );

  const handleThumbnailFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const firstFile = e.target.files[0];
      handleTemplateThumbnailBinaryChange(firstFile);
    }
  };

  const handleAnimatedThumbnailFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const firstFile = e.target.files[0];
      handleTemplateAnimatedThumbnailBinaryChange(firstFile);
    }
  };

  const handleTemplateStickerScrapInfosChange = React.useCallback((stickerId: string, newIsFrozen: boolean) => {
    setDraftTemplate((prevTemplate: SingleTemplateModel | MultiTemplateModel | null) => {
      if (!prevTemplate || prevTemplate instanceof MultiTemplateModel) return null;
      const updatedTemplate = prevTemplate.clone();
      updatedTemplate.updateStickerScrapInfo(stickerId, newIsFrozen);
      return updatedTemplate;
    });
  }, []);

  const handleTemplateSaveClick = React.useCallback(
    async (close: () => void) => {
      if (!draftTemplate) {
        close();
        return;
      }
      const error = await templateApi.updateTemplate(formAuthenticityToken, draftTemplate, templateTags);
      if (error) {
        picNotifier.notify({
          type: 'error',
          message: error.message,
        });
        close();
        return;
      }
      picNotifier.notify({
        type: 'success',
        message: 'Template updated successfully',
      });
      close();
      goToShowTemplatePage(draftTemplate.getId());
    },
    [draftTemplate, templateApi, picNotifier, goToShowTemplatePage],
  );

  const confirmAndSaveTemplate = React.useCallback(async () => {
    if (!draftTemplate) return;
    const title = 'Save Template';
    const message = `Are you sure you want to save your changes to the template ${draftTemplate.getId()} ?`;
    const [confirmed, close] = await picConfirmer.pop({ title, message });
    if (!confirmed) {
      close();
      return;
    }
    handleTemplateSaveClick(close);
  }, [draftTemplate, picConfirmer, handleTemplateSaveClick]);

  return (
    <div style={{ display: 'flex', flexFlow: 'column', gap: '36px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '36px' }}>
        <div style={{ display: 'flex', flexFlow: 'column', gap: '24px', flexBasis: '40%', minWidth: '41.666667%' }}>
          {draftTemplate && <PicImage src={draftTemplate.getDisplayThumbnailUrl()} width="100%" />}
          {draftTemplate && draftTemplate.getDisplayAnimatedThumbnailUrl() && (
            <div style={{ display: 'flex', flexFlow: 'column', gap: '12px' }}>
              <PicText size={PicFontSize.Xl} weight={PicFontWeight.Medium}>
                Animated Thumbnail
              </PicText>
              <PicImage src={draftTemplate.getDisplayAnimatedThumbnailUrl()} width="100%" />
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flexFlow: 'column', flexBasis: '60%', gap: '24px', maxWidth: '60%' }}>
          <div style={{ display: 'flex', flexFlow: 'column', gap: '12px' }}>
            <PicText size={PicFontSize.Xl} weight={PicFontWeight.Medium}>
              Template
            </PicText>
            {draftTemplate && (
              <div>
                <PicButton
                  copy="Save"
                  onClick={confirmAndSaveTemplate}
                  type="primary"
                  disabled={!suitablePhotosCountValidation.isValid}
                />
              </div>
            )}
            {draftTemplate && (
              <div style={{ display: 'flex', flexFlow: 'column', gap: '6px' }}>
                <PicRowField label="id" labelWidth="30%">
                  <PicText>{draftTemplate.getId()}</PicText>
                </PicRowField>
                <PicRowField label="author" labelWidth="30%">
                  <PicText>{draftTemplate.getAuthor()}</PicText>
                </PicRowField>
                <PicRowField label="uploader_info" labelWidth="30%">
                  <PicText>{draftTemplate.getUploaderInfo()}</PicText>
                </PicRowField>
                <PicRowField label="source url" labelWidth="30%">
                  <PicLink copy={draftTemplate.getSourceUrl() ?? ''} onClick={goToSourceUrl} />
                </PicRowField>
                <PicRowField label="created at" labelWidth="30%">
                  <PicText>{draftTemplate.getCreatedAt()}</PicText>
                </PicRowField>
                <PicRowField label="updated at" labelWidth="30%">
                  <PicText>{draftTemplate.getUpdatedAt()}</PicText>
                </PicRowField>
                <PicRowField label="adjustable?" labelWidth="30%">
                  <PicCheckbox checked={draftTemplate.getIsAdjustable()} onChange={handleTemplateIsAdjustableChange} />
                </PicRowField>
                {draftTemplate instanceof SingleTemplateModel && (
                  <PicRowField label="creator id" labelWidth="30%">
                    <PicDropdown
                      value={draftTemplate.getCreatorId()}
                      placeholder="Select creator id"
                      itemGroups={templateCreatorIdsGroups}
                      enableFilter
                      itemLabelGetter={(item) => item}
                      itemValueGetter={(item) => item}
                      onSelect={handleTemplateCreatorIdChange}
                    />
                  </PicRowField>
                )}
                <PicRowField label="suitable photos count" labelWidth="30%">
                  <div style={{ display: 'flex', flexFlow: 'column', gap: '6px' }}>
                    <div style={{ display: 'flex', flexFlow: 'row', gap: '12px', alignItems: 'center' }}>
                      <PicNumberInput
                        value={draftTemplate.getLowerSuitablePhotosCount()}
                        step={1}
                        min={minimumSuitablePhotosCount}
                        max={maximumSuitablePhotosCount}
                        onInput={handleTemplateLowerSuitablePhotosCountChange}
                      />
                      <PicNumberInput
                        value={draftTemplate.getUpperSuitablePhotosCount()}
                        step={1}
                        min={minimumSuitablePhotosCount}
                        max={maximumSuitablePhotosCount}
                        onInput={handleTemplateUpperSuitablePhotosCountChange}
                      />
                      <PicIconButton
                        iconName={PicIconName.Close}
                        onClick={() => {
                          handleTemplateUpperSuitablePhotosCountChange(null);
                        }}
                      />
                    </div>
                    {suitablePhotosCountValidation.isValid ? (
                      <PicText color={PicColor.Yellow500} size={PicFontSize.Sm} weight={PicFontWeight.Medium}>
                        Preview suitable photos count: {draftTemplate.getSuitablePhotosCount()}
                      </PicText>
                    ) : (
                      <PicText color={PicColor.Red500} size={PicFontSize.Sm} weight={PicFontWeight.Medium}>
                        {suitablePhotosCountValidation.message}
                      </PicText>
                    )}
                  </div>
                </PicRowField>
                <PicRowField label="categories" labelWidth="30%">
                  <div style={{ display: 'flex', gap: '2px', alignItems: 'center', flexWrap: 'wrap' }}>
                    <PicTagsInput
                      value={draftTemplate
                        .getTemplateTagIds()
                        .map((tagId) => getTemplateTagNameById(tagId, templateTags))
                        .concat(draftTemplate.getnewTemplateTagNames())}
                      suggestions={templateTags.map((tag) => tag.getName())}
                      onInput={handleTemplateCategoriesChange}
                    />
                  </div>
                </PicRowField>
                <PicRowField label="labels" labelWidth="30%">
                  <div style={{ display: 'flex', gap: '2px', alignItems: 'center', flexWrap: 'wrap' }}>
                    <PicTagsInput
                      value={draftTemplate.getLabels()}
                      suggestions={templateLabelNames}
                      onInput={handleTemplateLabelsChange}
                    />
                  </div>
                </PicRowField>
                <PicRowField label="thumbnail" labelWidth="30%">
                  <div style={{ display: 'flex', flexFlow: 'row', gap: '12px', alignItems: 'center' }}>
                    {/* <PicImageUpload
                      accept={acceptableMimeTypes.join(',')}
                      onUpload={handleTemplateThumbnailBinaryChange}
                    /> */}
                    <input
                      ref={thumbnailInputRef}
                      type="file"
                      style={{
                        border: `1px solid ${PicColor.Grey300}`,
                        borderRadius: '5px',
                        padding: '4px',
                        outline: 'none',
                        background: PicColor.White,
                      }}
                      accept={acceptableMimeTypes.join(',')}
                      onChange={handleThumbnailFileChange}
                    />
                    <PicLink copy="Reset" onClick={() => handleTemplateThumbnailBinaryChange(null)} />
                  </div>
                </PicRowField>

                <PicRowField label="animated thumbnail" labelWidth="30%">
                  <div style={{ display: 'flex', flexFlow: 'row', gap: '12px', alignItems: 'center' }}>
                    {/* <PicImageUpload
                      accept={acceptableMimeTypes.join(',')}
                      onUpload={handleTemplateAnimatedThumbnailBinaryChange}
                    /> */}
                    <input
                      ref={animatedThumbnailInputRef}
                      type="file"
                      style={{
                        border: `1px solid ${PicColor.Grey300}`,
                        borderRadius: '5px',
                        padding: '4px',
                        outline: 'none',
                        background: PicColor.White,
                      }}
                      accept={acceptableMimeTypes.join(',')}
                      onChange={handleAnimatedThumbnailFileChange}
                    />
                    <PicLink copy="Reset" onClick={() => handleTemplateAnimatedThumbnailBinaryChange(null)} />
                  </div>
                </PicRowField>
                <PicRowField label="should remove animated thumbnail" labelWidth="30%">
                  <PicCheckbox
                    checked={draftTemplate.getShouldRemoveAnimatedThumbnail()}
                    disabled={!draftTemplate.getAnimatedThumbnailUrl()}
                    onChange={handleTemplateShouldRemoveAnimatedThumbnailChange}
                  />
                </PicRowField>
                <PicRowField label="minimal version iOS" labelWidth="30%">
                  <PicText>{draftTemplate.getMinimalRequiredVersionIos()?.toString()}</PicText>
                </PicRowField>
                <PicRowField label="minimal version Android" labelWidth="30%">
                  <PicText>{draftTemplate.getMinimalRequiredVersionAndroid()?.toString()}</PicText>
                </PicRowField>
              </div>
            )}
          </div>
          <div>
            {draftTemplate instanceof SingleTemplateModel && (
              <SingleTemplateContentSection
                template={draftTemplate}
                isEditingStickersMovableStatus={true}
                handleTemplateStickerScrapInfosChange={handleTemplateStickerScrapInfosChange}
              />
            )}
          </div>
          <div>
            {draftTemplate instanceof MultiTemplateModel && <MultiTemplateContentSection template={draftTemplate} />}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {draftTemplate && draftTemplate instanceof SingleTemplateModel && (
          <div style={{ display: 'flex', flexFlow: 'column', gap: '24px' }}>
            <PicText size={PicFontSize.Xl} weight={PicFontWeight.Medium}>
              Template Structure (Raw Data)
            </PicText>
            <PicContainer borderRadius="10px">
              <pre
                style={{
                  padding: '12px',
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '12px',
                }}
              >
                {JSON.stringify(draftTemplate.getCollageStruct(), null, 2)}
              </pre>
            </PicContainer>
          </div>
        )}
      </div>
    </div>
  );
};

const EditTemplatePage: React.FC<Props> = (props) => {
  return (
    <CommonContextProvider>
      <EditTemplatePageContent {...props} />
    </CommonContextProvider>
  );
};

export default EditTemplatePage;
