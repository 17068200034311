import * as React from 'react';
import { SingleTemplateModel } from '../../common/models/single-template-model';
import {
  PicCheckbox,
  PicColor,
  PicDropdown,
  PicFontSize,
  PicFontWeight,
  PicLink,
  PicNotifier,
  PicRowField,
  PicText,
} from '@cardinalblue/pic-collage-cms-ui';

interface Props {
  template: SingleTemplateModel;
  isEditingStickersMovableStatus?: boolean;
  handleTemplateStickerScrapInfosChange?: (stickerId: string, newIsFrozen: boolean) => void;
}

const SingleTemplateContentSection: React.FC<Props> = ({
  template,
  isEditingStickersMovableStatus = false,
  handleTemplateStickerScrapInfosChange,
}) => {
  const picNotifier = React.useMemo(() => new PicNotifier(), []);
  const textScrapInfos = template.getTextScrapInfos();
  const stickerScrapInfos = template.getStickerScrapInfos();
  const backgroundScrapInfos = template.getBackgroundScrapInfos();
  const photoScrapInfos = template.getPhotoScrapInfos();
  const frozenText = '⛔️ Frozen';
  const unfrozenText = '♻️ Movable';

  const stickerIsFrozenGroups = [{ title: '', items: [frozenText, unfrozenText] }];

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => picNotifier.notify({ type: 'success', message: 'Copied to clipboard' }));
  };

  return (
    <div style={{ display: 'flex', flexFlow: 'column', gap: '12px' }}>
      <PicText size={PicFontSize.Xl} weight={PicFontWeight.Medium}>
        Contents
      </PicText>
      <PicText>💡 You can click scrap ids to quickly copy and paste them to your clipboard</PicText>

      {textScrapInfos && textScrapInfos.length > 0 && (
        <PicRowField label="Text Scraps" labelWidth="30%">
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                {['Text', 'Font Style'].map((header, index) => (
                  <th
                    key={index}
                    style={{
                      border: `1px solid ${PicColor.Grey300}`,
                      borderBottomWidth: '3px',
                      textAlign: 'left',
                      padding: '8px',
                    }}
                  >
                    <PicText>{header}</PicText>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {textScrapInfos.map((text, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: `1px solid ${PicColor.Grey300}`,
                      textAlign: 'left',
                      padding: '8px',
                    }}
                  >
                    <PicText>{text.text}</PicText>
                  </td>
                  <td
                    style={{
                      border: `1px solid ${PicColor.Grey300}`,
                      textAlign: 'left',
                      padding: '8px',
                    }}
                  >
                    <PicText>{text.fontName}</PicText>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PicRowField>
      )}

      {stickerScrapInfos && stickerScrapInfos.length > 0 && (
        <PicRowField label="Sticker Scraps" labelWidth="30%">
          <div
            style={{
              display: 'flex',
              gap: '12px',
              flexWrap: 'wrap',
              alignItems: 'flex-end',
              backgroundColor: PicColor.Grey200,
              padding: '12px',
            }}
          >
            {stickerScrapInfos.map((sticker) => (
              <div key={sticker.id} style={{ maxHeight: '160px' }}>
                <a href={sticker.imageUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    src={sticker.imageUrl}
                    alt="sticker"
                    style={{
                      display: 'flex',
                      flexFlow: 'column',
                      gap: '4px',
                      maxWidth: '100px',
                      maxHeight: '100px',
                      margin: '0 5px 5px 0',
                    }}
                  />
                </a>
                {isEditingStickersMovableStatus && handleTemplateStickerScrapInfosChange ? (
                  <PicDropdown
                    value={sticker.isFrozen ? frozenText : unfrozenText}
                    placeholder="Select status"
                    itemGroups={stickerIsFrozenGroups}
                    itemLabelGetter={(item) => item}
                    itemValueGetter={(item) => item}
                    onSelect={(item) => handleTemplateStickerScrapInfosChange(sticker.id, item === frozenText)}
                  />
                ) : (
                  <PicText>{sticker.isFrozen ? frozenText : unfrozenText}</PicText>
                )}
                <PicLink onClick={() => copyToClipboard(sticker.id)} copy={sticker.id} />
              </div>
            ))}
          </div>
        </PicRowField>
      )}

      {backgroundScrapInfos && backgroundScrapInfos.length > 0 && (
        <PicRowField label="Background Scraps" labelWidth="30%">
          <div
            style={{
              display: 'flex',
              gap: '12px',
              flexWrap: 'wrap',
              alignItems: 'flex-end',
              backgroundColor: PicColor.Grey200,
              padding: '12px',
            }}
          >
            {backgroundScrapInfos.map((background) => (
              <div key={background.id} style={{ maxHeight: '160px' }}>
                <a href={background.imageUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    src={background.imageUrl}
                    alt="background"
                    style={{
                      display: 'flex',
                      flexFlow: 'column',
                      gap: '4px',
                      maxWidth: '100px',
                      maxHeight: '100px',
                      margin: '0 5px 5px 0',
                    }}
                  />
                </a>
                <PicLink onClick={() => copyToClipboard(background.id)} copy={background.id} />
              </div>
            ))}
          </div>
        </PicRowField>
      )}

      {template.getBackgroundInfo() && (
        <PicRowField label="Background Structure" labelWidth="30%">
          <pre>
            <code>{JSON.stringify(template.getBackgroundInfo(), null, 2)}</code>
          </pre>
        </PicRowField>
      )}

      {photoScrapInfos && photoScrapInfos.length > 0 && (
        <PicRowField label="Photo Scraps" labelWidth="30%">
          <div
            style={{
              display: 'flex',
              gap: '12px',
              flexWrap: 'wrap',
              alignItems: 'flex-end',
              backgroundColor: PicColor.Grey200,
              padding: '12px',
            }}
          >
            {photoScrapInfos.map((photo) => (
              <div key={photo.id} style={{ maxHeight: '160px' }}>
                <a href={photo.imageUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    src={photo.imageUrl}
                    alt="photo"
                    style={{
                      display: 'flex',
                      flexFlow: 'column',
                      gap: '4px',
                      maxWidth: '100px',
                      maxHeight: '100px',
                      margin: '0 5px 5px 0',
                    }}
                  />
                </a>
                <PicText>{photo.isFrozen ? frozenText : unfrozenText}</PicText>
                <PicLink onClick={() => copyToClipboard(photo.id)} copy={photo.id} />
              </div>
            ))}
          </div>
        </PicRowField>
      )}

      {template.getAnimationInfo() && (
        <PicRowField label="Animation Structure" labelWidth="30%">
          <pre>
            <code>{JSON.stringify(template.getAnimationInfo(), null, 2)}</code>
          </pre>
        </PicRowField>
      )}

      {template.getRecipeInfo() && (
        <PicRowField label="Recipe Structure" labelWidth="30%">
          <pre>
            <code>{JSON.stringify(template.getRecipeInfo(), null, 2)}</code>
          </pre>
        </PicRowField>
      )}
    </div>
  );
};

export default SingleTemplateContentSection;
