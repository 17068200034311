import * as React from 'react';
import { CommonContextProvider } from '../features/common/contexts/common-context';
import {
  PicButton,
  PicRowField,
  PicText,
  PicNotifier,
  PicLink,
  PicRouter,
  PicContainer,
  PicFontSize,
  PicFontWeight,
  PicTag,
  PicColor,
} from '@cardinalblue/pic-collage-cms-ui';
import { TemplateApi } from '../features/templates/apis/template-api.ts';
import { SingleTemplateModel } from '../features/common/models/single-template-model';
import { MultiTemplateModel } from '../features/common/models/multi-template-model';
import { parseTemplateTagDto, TemplateTagDto } from '../features/common/dtos/template-tag-dto';
import SingleTemplateContentSection from '../features/templates/components/single-template-contents';
import MultiTemplateContentSection from '../features/templates/components/multi-template-contents';

type Props = {
  formAuthenticityToken: string;
  templateId: number;
  templateTagDtos: TemplateTagDto[];
};

const ShowTemplatePageContent: React.FC<Props> = ({ formAuthenticityToken, templateId, templateTagDtos }) => {
  const templateApi = React.useMemo(() => TemplateApi.create(), []);
  const templateTags = React.useMemo(() => templateTagDtos.map((dto) => parseTemplateTagDto(dto)), [templateTagDtos]);
  const picNotifier = React.useMemo(() => new PicNotifier(), []);
  const picRouter = React.useMemo(() => new PicRouter(), []);
  const [template, setTemplate] = React.useState<SingleTemplateModel | MultiTemplateModel | null>(null);

  const fetchTemplate = React.useCallback(async (): Promise<Error | null> => {
    const [error, returnedTemplate] = await templateApi.fetchTemplatebyId(templateId);
    if (error) {
      picNotifier.notify({
        type: 'error',
        message: error.message,
      });
      return error;
    }
    setTemplate(returnedTemplate);
    return null;
  }, [templateApi, picNotifier]);

  React.useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  const getTemplateTagNameById = React.useCallback(
    (templateTagId: number) => {
      const templateTag = templateTags.find((tag) => tag.getId() === templateTagId);
      if (!templateTag) {
        return '';
      }
      return templateTag.getName();
    },
    [templateTags],
  );

  const goToSourceUrl = React.useCallback(() => {
    if (template?.getSourceUrl()) {
      picRouter.open(template.getSourceUrl());
    }
  }, [template]);

  const getTemplateTagEditPage = React.useCallback(
    (templateTagId: number) => `/admin/template_tags/${templateTagId}/edit`,
    [],
  );

  return (
    <div style={{ display: 'flex', flexFlow: 'column', gap: '36px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '36px' }}>
        <div style={{ flexBasis: '40%', minWidth: '41.666667%' }}>
          {template && template.getThumbnailUrl() && (
            <img src={template.getThumbnailUrl()} alt="template image" style={{ width: '100%' }} />
          )}
          {template && template.getAnimatedThumbnailUrl() && (
            <>
              <PicText size={PicFontSize.Xl} weight={PicFontWeight.Medium}>
                Animated Thumbnail
              </PicText>
              <img src={template?.getAnimatedThumbnailUrl()} alt="animated image" style={{ width: '100%' }} />
            </>
          )}
        </div>
        <div style={{ display: 'flex', flexFlow: 'column', flexBasis: '60%', gap: '24px', maxWidth: '60%' }}>
          <div style={{ display: 'flex', flexFlow: 'column', gap: '12px' }}>
            <PicText size={PicFontSize.Xl} weight={PicFontWeight.Medium}>
              Template
            </PicText>
            {template && (
              <div style={{ width: 'fit-content' }}>
                <PicButton href={`/templates/${templateId}/edit`} copy="Edit" type="primary" />
              </div>
            )}
            {template && (
              <div style={{ display: 'flex', flexFlow: 'column', gap: '6px' }}>
                <PicRowField label="id" labelWidth="30%">
                  <PicText>{template.getId()}</PicText>
                </PicRowField>
                <PicRowField label="author" labelWidth="30%">
                  <PicText>{template.getAuthor()}</PicText>
                </PicRowField>
                <PicRowField label="uploader_info" labelWidth="30%">
                  <PicText>{template.getUploaderInfo()}</PicText>
                </PicRowField>
                <PicRowField label="source url" labelWidth="30%">
                  <PicLink copy={template.getSourceUrl() ?? ''} onClick={goToSourceUrl} />
                </PicRowField>
                <PicRowField label="created at" labelWidth="30%">
                  <PicText>{template.getCreatedAt()}</PicText>
                </PicRowField>
                <PicRowField label="updated at" labelWidth="30%">
                  <PicText>{template.getUpdatedAt()}</PicText>
                </PicRowField>
                <PicRowField label="adjustable?" labelWidth="30%">
                  <PicText>{template.getIsAdjustable().toString()}</PicText>
                </PicRowField>
                {template instanceof SingleTemplateModel && (
                  <PicRowField label="creator id" labelWidth="30%">
                    <PicText>{template.getCreatorId()}</PicText>
                  </PicRowField>
                )}
                <PicRowField label="suitable photos count" labelWidth="30%">
                  <PicText>{template.getSuitablePhotosCount()}</PicText>
                </PicRowField>
                <PicRowField label="categories" labelWidth="30%">
                  <div style={{ display: 'flex', gap: '2px', alignItems: 'center', flexWrap: 'wrap' }}>
                    {template.getTemplateTagIds().map((tagId, index, array) => (
                      <React.Fragment key={tagId}>
                        <PicLink copy={getTemplateTagNameById(tagId)} href={getTemplateTagEditPage(tagId)} />
                        {index < array.length - 1 && <PicText>{', '}</PicText>}
                      </React.Fragment>
                    ))}
                  </div>
                </PicRowField>
                <PicRowField label="labels" labelWidth="30%">
                  <div style={{ display: 'flex', gap: '2px', alignItems: 'center', flexWrap: 'wrap' }}>
                    {template.getLabels().map((label) => (
                      <PicTag key={label} copy={label} color={PicColor.Teal500} />
                    ))}
                  </div>
                </PicRowField>
                <PicRowField label="minimal version iOS" labelWidth="30%">
                  <PicText>{template.getMinimalRequiredVersionIos()?.toString()}</PicText>
                </PicRowField>
                <PicRowField label="minimal version Android" labelWidth="30%">
                  <PicText>{template.getMinimalRequiredVersionAndroid()?.toString()}</PicText>
                </PicRowField>
              </div>
            )}
          </div>
          <div>{template instanceof SingleTemplateModel && <SingleTemplateContentSection template={template} />}</div>
          <div>{template instanceof MultiTemplateModel && <MultiTemplateContentSection template={template} />}</div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {template && template instanceof SingleTemplateModel && (
          <div style={{ display: 'flex', flexFlow: 'column', gap: '24px' }}>
            <PicText size={PicFontSize.Xl} weight={PicFontWeight.Medium}>
              Template Structure (Raw Data)
            </PicText>
            <PicContainer borderRadius="10px">
              <pre
                style={{
                  padding: '12px',
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '12px',
                }}
              >
                {JSON.stringify(template.getCollageStruct(), null, 2)}
              </pre>
            </PicContainer>
          </div>
        )}
      </div>
    </div>
  );
};

const ShowTemplatePage: React.FC<Props> = (props) => {
  return (
    <CommonContextProvider>
      <ShowTemplatePageContent {...props} />
    </CommonContextProvider>
  );
};

export default ShowTemplatePage;
