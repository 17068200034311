import { parseSingleTemplateDto, SingleTemplateDto } from '../../common/dtos/single-template-dto';
import { parseMultiTemplateDto, MultiTemplateDto } from '../../common/dtos/multi-template-dto';
import { SingleTemplateModel } from '../../common/models/single-template-model';
import { MultiTemplateModel } from '../../common/models/multi-template-model';
import { TemplateTagModel } from '../../common/models/template-tag-model';
import { getTemplateTagNameById } from '../../common/utils/template-tag-helpers';

export class TemplateApi {
  static create() {
    return new TemplateApi();
  }

  async fetchTemplatebyId(
    templateId: number,
  ): Promise<[error: Error | null, template: SingleTemplateModel | MultiTemplateModel]> {
    const response = await fetch(`/cms_api/templates/${templateId}`);

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return [new Error(error), SingleTemplateModel.createMock()];
    } else {
      const templateDto = (await response.json()) as SingleTemplateDto | MultiTemplateDto;
      let templateModel: SingleTemplateModel | MultiTemplateModel;
      if (templateDto.is_multipage) {
        templateModel = parseMultiTemplateDto(templateDto as MultiTemplateDto);
      } else {
        templateModel = parseSingleTemplateDto(templateDto as SingleTemplateDto);
      }
      return [null, templateModel];
    }
  }

  async updateTemplate(
    formAuthenticityToken: string,
    template: SingleTemplateModel | MultiTemplateModel,
    templateTags: TemplateTagModel[],
  ): Promise<Error | null> {
    const formData = new FormData();

    formData.append('_method', 'put');
    formData.append('authenticity_token', formAuthenticityToken);
    formData.append('commit', 'Save');
    formData.append('is_adjustable', template.getIsAdjustable().toString());
    formData.append('suitable_photos_count', template.getSuitablePhotosCount().toString());
    formData.append(
      'template_tag_names',
      template
        .getTemplateTagIds()
        .map((tagId) => getTemplateTagNameById(tagId, templateTags))
        .concat(template.getNewCategories())
        .join(','),
    );
    formData.append('template_label_names', template.getLabels().join(','));
    if (template instanceof SingleTemplateModel) {
      formData.append('creator_id', template.getCreatorId().toString());
      template.getStickerScrapInfos().forEach((stickerScrapInfo) => {
        formData.append(`is_frozen[${stickerScrapInfo.id}]`, stickerScrapInfo.isFrozen.toString());
      });
    }

    const thumbnailBinary = template.getThumbnailBinary();
    if (thumbnailBinary) {
      formData.append('thumbnail', new Blob([thumbnailBinary]));
    }
    const animatedThumbnailBinary = template.getAnimatedThumbnailBinary();
    if (animatedThumbnailBinary) {
      formData.append('animated_thumbnail', new Blob([animatedThumbnailBinary]));
    }
    const shouldRemoveAnimatedThumbnail = template.getShouldRemoveAnimatedThumbnail();
    if (shouldRemoveAnimatedThumbnail) {
      formData.append('should_remove_animated_thumbnail', shouldRemoveAnimatedThumbnail.toString());
    }

    const response = await fetch(`/templates/${template.getId()}`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return new Error(error);
    } else {
      return null;
    }
  }
}
