import { generateObjectUrlFromUnit8Array } from '../utils/file';
import { AppVersionVo } from './app-version-vo';
import { TemplateSizeCateoryEnum } from './template-size-category-enum';

export class TemplateModel {
  /**
    TemplateModel has detailed information of a template, unlike InTagTemplate which is for
    bulk edit/show templates under a template tag.
  */
  constructor(
    private id: number,
    private author: string,
    private uploaderInfo: string,
    private sourceUrl: string,
    private isVip: boolean,
    private isAnimated: boolean,
    private thumbnailUrl: string,
    private animatedThumbnailUrl: string,
    private sizeCategory: TemplateSizeCateoryEnum,
    private slotsCount: number,
    private createdAt: string,
    private updatedAt: string,
    private isMultipage: boolean,
    private isAdjustable: boolean,
    private lowerSuitablePhotosCount: number | null,
    private upperSuitablePhotosCount: number | null,
    private labels: string[],
    private minimalRequiredVersionIos: AppVersionVo | null,
    private minimalRequiredVersionAndroid: AppVersionVo | null,
    private templateTagIds: number[],
    private shouldRemoveAnimatedThumbnail: boolean = false,
    private thumbnailBinary: Uint8Array | null = null,
    private animatedThumbnailBinary: Uint8Array | null = null,
    private newTemplateTagNames: string[] = [],
  ) {}

  public getId() {
    return this.id;
  }

  public getAuthor() {
    return this.author;
  }

  public getUploaderInfo() {
    return this.uploaderInfo;
  }

  public getSourceUrl() {
    return this.sourceUrl;
  }

  public getIsVip() {
    return this.isVip;
  }

  public getIsAnimated() {
    return this.isAnimated;
  }

  public getThumbnailUrl() {
    return this.thumbnailUrl;
  }

  public getThumbnailBinary() {
    return this.thumbnailBinary;
  }

  public getDisplayThumbnailUrl() {
    const thumbnailBinary = this.getThumbnailBinary();
    if (!thumbnailBinary) return this.thumbnailUrl;
    return generateObjectUrlFromUnit8Array(thumbnailBinary);
  }

  public getAnimatedThumbnailUrl() {
    return this.animatedThumbnailUrl;
  }

  public getDisplayAnimatedThumbnailUrl() {
    const animatedThumbnailBinary = this.getAnimatedThumbnailBinary();
    if (!animatedThumbnailBinary) return this.animatedThumbnailUrl;
    if (this.shouldRemoveAnimatedThumbnail) return '';
    return generateObjectUrlFromUnit8Array(animatedThumbnailBinary);
  }

  public getAnimatedThumbnailBinary() {
    return this.animatedThumbnailBinary;
  }

  public getSizeCategory() {
    return this.sizeCategory;
  }

  public getSlotsCount() {
    return this.slotsCount;
  }

  public getCreatedAt() {
    return this.createdAt;
  }

  public getUpdatedAt() {
    return this.updatedAt;
  }

  public getIsMultipage() {
    return this.isMultipage;
  }

  public getIsAdjustable() {
    return this.isAdjustable;
  }

  public getLowerSuitablePhotosCount() {
    return this.lowerSuitablePhotosCount;
  }

  public getUpperSuitablePhotosCount() {
    return this.upperSuitablePhotosCount;
  }

  public getSuitablePhotosCount() {
    return this.formatSuitablePhotosCount(this.lowerSuitablePhotosCount, this.upperSuitablePhotosCount);
  }

  public getLabels() {
    return this.labels;
  }

  public getMinimalRequiredVersionIos() {
    return this.minimalRequiredVersionIos;
  }

  public getMinimalRequiredVersionAndroid() {
    return this.minimalRequiredVersionAndroid;
  }

  public getTemplateTagIds() {
    return this.templateTagIds;
  }

  public getnewTemplateTagNames() {
    return this.newTemplateTagNames;
  }

  public getShouldRemoveAnimatedThumbnail() {
    return this.shouldRemoveAnimatedThumbnail;
  }

  public updateShouldRemoveAnimatedThumbnail(shouldRemoveAnimatedThumbnail: boolean) {
    this.shouldRemoveAnimatedThumbnail = shouldRemoveAnimatedThumbnail;
  }

  public updateThumbnailBinary(thumbnailBinary: Uint8Array | null) {
    this.thumbnailBinary = thumbnailBinary;
  }

  public updateAnimatedThumbnailBinary(animatedThumbnailBinary: Uint8Array | null) {
    this.animatedThumbnailBinary = animatedThumbnailBinary;
  }

  public updateIsAdjustable(isAdjustable: boolean) {
    this.isAdjustable = isAdjustable;
  }

  public updateLowerSuitablePhotosCount(lowerSuitablePhotosCount: number | null) {
    this.lowerSuitablePhotosCount = lowerSuitablePhotosCount;
  }

  public updateUpperSuitablePhotosCount(upperSuitablePhotosCount: number | null) {
    this.upperSuitablePhotosCount = upperSuitablePhotosCount;
  }

  public updateTemplateTagIds(templateTagIds: number[]) {
    this.templateTagIds = templateTagIds;
  }

  public updateLabels(labels: string[]) {
    this.labels = labels;
  }

  public updateNewTemplateTagNames(newTemplateTagNames: string[]) {
    this.newTemplateTagNames = newTemplateTagNames;
  }

  public clone() {
    return new TemplateModel(
      this.id,
      this.author,
      this.uploaderInfo,
      this.sourceUrl,
      this.isVip,
      this.isAnimated,
      this.thumbnailUrl,
      this.animatedThumbnailUrl,
      this.sizeCategory,
      this.slotsCount,
      this.createdAt,
      this.updatedAt,
      this.isMultipage,
      this.isAdjustable,
      this.lowerSuitablePhotosCount,
      this.upperSuitablePhotosCount,
      this.labels,
      this.minimalRequiredVersionIos,
      this.minimalRequiredVersionAndroid,
      this.templateTagIds,
    );
  }

  private formatSuitablePhotosCount(lowerSuitablePhotosCount: number | null, upperSuitablePhotosCount: number | null) {
    if (!lowerSuitablePhotosCount && !upperSuitablePhotosCount) {
      return '';
    }
    if (!upperSuitablePhotosCount) {
      return `${lowerSuitablePhotosCount}+`;
    }
    if (lowerSuitablePhotosCount !== upperSuitablePhotosCount) {
      return `${lowerSuitablePhotosCount}~${upperSuitablePhotosCount}`;
    }
    return lowerSuitablePhotosCount.toString();
  }
}
