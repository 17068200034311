import * as React from 'react';
import { TemplateTagModel } from '../models/template-tag-model';

/**
 * Retrieves the name of a template tag by its ID.
 * @param templateTagId - The ID of the template tag.
 * @returns The name of the template tag, or an empty string if not found.
 */

export const getTemplateTagNameById = (templateTagId: number | null, templateTags: TemplateTagModel[]): string => {
  if (!templateTagId) {
    return '';
  }
  const templateTag = templateTags.find((tag) => tag.getId() === templateTagId);
  if (!templateTag) {
    return '';
  }
  return templateTag.getName();
};
